/** @format */

import React from 'react'
import { Link } from '../TransitionLink/TransitionLink'

import { StyledLogo } from './StyledLogo'

const Logo = ({
  hasMaxWidth,
  hadDeclaredMargin,
  isHeader,
  isFooter,
  onClick
}) => {
  return (
    <Link onClick={onClick} to='/' aria-label='Strona główna'>
      <StyledLogo
        isheader={isHeader}
        hasmaxwidth={hasMaxWidth}
        hasdeclaredmargin={hadDeclaredMargin}
        isfooter={isFooter}>
       <svg
          width="396"
          height="48"
          viewBox="0 0 396 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="logo"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.09556 12.0699C-0.698555 15.04 -0.698515 19.7578 2.09565 22.7278L16.4159 37.9496C22.1167 44.0109 29.6221 47.0409 37.122 47.0409C44.6205 47.0409 52.1273 44.0109 57.8294 37.9496L72.1485 22.7285C74.9426 19.7585 74.9426 15.0408 72.1485 12.0707L70.7647 10.5997C67.8422 7.49311 63.0029 7.49311 60.0804 10.5997L46.0903 25.471C43.6319 28.0844 40.3786 29.3921 37.122 29.3914C33.8647 29.3921 30.6128 28.0851 28.1544 25.471L14.1636 10.599C11.2411 7.49238 6.40181 7.49238 3.47929 10.599L2.09556 12.0699Z"
            fill="black"
          />
          <path
            d="M112.732 34.721H107.112L97.7134 20.0684V34.721H92.0939V10.9659H97.7134L107.112 25.6855V10.9659H112.732V34.721Z"
            fill="black"
          />
          <path
            d="M128.259 21.8622C127.481 21.0281 126.533 20.6104 125.417 20.6104C124.299 20.6104 123.351 21.0223 122.574 21.8454C121.796 22.6686 121.407 23.8025 121.407 25.2457C121.407 26.6903 121.796 27.8351 122.574 28.6808C123.351 29.5265 124.299 29.9494 125.417 29.9494C126.533 29.9494 127.481 29.5323 128.259 28.6976C129.036 27.8635 129.426 26.7238 129.426 25.2799C129.426 23.8367 129.036 22.6969 128.259 21.8622ZM116.757 20.1359C117.468 18.6701 118.438 17.542 119.665 16.7524C120.892 15.9634 122.262 15.5683 123.773 15.5683C125.065 15.5683 126.199 15.839 127.174 16.3805C128.149 16.9212 128.9 17.6323 129.426 18.5122V15.839H135.045V34.7207H129.426V32.0476C128.877 32.9275 128.116 33.6385 127.141 34.1793C126.167 34.7207 125.033 34.9922 123.74 34.9922C122.25 34.9922 120.892 34.5912 119.665 33.7906C118.438 32.9901 117.468 31.8503 116.757 30.3729C116.045 28.8955 115.689 27.1867 115.689 25.2457C115.689 23.3061 116.045 21.6031 116.757 20.1359Z"
            fill="black"
          />
          <path
            d="M149.274 34.1458C148.025 33.5825 147.039 32.8096 146.316 31.8278C145.593 30.8467 145.188 29.747 145.101 28.5287H150.654C150.719 29.183 151.016 29.7128 151.541 30.1189C152.067 30.525 152.713 30.7281 153.48 30.7281C154.181 30.7281 154.723 30.5876 155.107 30.3053C155.49 30.0229 155.682 29.6568 155.682 29.2048C155.682 28.6641 155.408 28.2638 154.86 28.004C154.313 27.7449 153.426 27.4574 152.199 27.1408C150.884 26.8257 149.789 26.4931 148.912 26.143C148.036 25.793 147.28 25.2406 146.645 24.4851C146.01 23.729 145.692 22.7079 145.692 21.4226C145.692 20.3396 145.982 19.3528 146.563 18.4612C147.143 17.5704 147.998 16.8652 149.126 16.3463C150.254 15.8281 151.596 15.5683 153.151 15.5683C155.452 15.5683 157.265 16.1549 158.591 17.328C159.916 18.5005 160.677 20.058 160.874 21.9975H155.682C155.594 21.3433 155.315 20.8251 154.844 20.4408C154.373 20.058 153.754 19.8658 152.988 19.8658C152.33 19.8658 151.826 19.9961 151.476 20.2552C151.125 20.5143 150.95 20.8695 150.95 21.3207C150.95 21.8622 151.229 22.2683 151.788 22.539C152.346 22.8098 153.217 23.0805 154.4 23.3512C155.759 23.7122 156.865 24.0674 157.719 24.4167C158.574 24.7668 159.324 25.3308 159.97 26.1088C160.616 26.8876 160.951 27.9312 160.973 29.239C160.973 30.3446 160.672 31.3322 160.069 32.2004C159.466 33.0687 158.601 33.7513 157.473 34.2477C156.345 34.7433 155.036 34.9922 153.546 34.9922C151.946 34.9922 150.523 34.7098 149.274 34.1458Z"
            fill="black"
          />
          <path
            d="M190.745 15.8385L185.783 34.721H179.571L176.68 22.4708L173.69 34.721H167.511L162.516 15.8385H168.136L170.732 29.3404L173.821 15.8385H179.769L182.891 29.2727L185.454 15.8385H190.745Z"
            fill="black"
          />
          <path
            d="M203.972 28.766C204.728 27.9538 205.106 26.7922 205.106 25.2806C205.106 23.769 204.739 22.6074 204.005 21.7945C203.271 20.983 202.367 20.5769 201.293 20.5769C200.198 20.5769 199.29 20.9772 198.567 21.7778C197.844 22.579 197.482 23.7464 197.482 25.2806C197.482 26.7922 197.837 27.9538 198.55 28.766C199.261 29.5782 200.155 29.9843 201.228 29.9843C202.301 29.9843 203.217 29.5782 203.972 28.766ZM196.381 33.8081C194.946 33.0185 193.818 31.8904 192.996 30.4239C192.175 28.9581 191.764 27.2434 191.764 25.2806C191.764 23.3403 192.18 21.6315 193.013 20.1541C193.845 18.6766 194.984 17.5427 196.43 16.7531C197.876 15.9634 199.497 15.5683 201.293 15.5683C203.09 15.5683 204.711 15.9634 206.157 16.7531C207.603 17.5427 208.742 18.6766 209.575 20.1541C210.407 21.6315 210.824 23.3403 210.824 25.2806C210.824 27.2209 210.402 28.9297 209.558 30.4071C208.715 31.8853 207.565 33.0185 206.108 33.8081C204.651 34.5978 203.024 34.9922 201.228 34.9922C199.432 34.9922 197.815 34.5978 196.381 33.8081Z"
            fill="black"
          />
          <path
            d="M219.434 34.721H213.815V15.8385H219.434V34.721ZM214.225 12.9797C213.6 12.3822 213.289 11.6428 213.289 10.7629C213.289 9.86117 213.6 9.11081 214.225 8.51256C214.85 7.91505 215.654 7.61593 216.641 7.61593C217.604 7.61593 218.399 7.91505 219.023 8.51256C219.648 9.11081 219.959 9.86117 219.959 10.7629C219.959 11.6428 219.648 12.3822 219.023 12.9797C218.399 13.5772 217.604 13.8764 216.641 13.8764C215.654 13.8764 214.85 13.5772 214.225 12.9797Z"
            fill="black"
          />
          <path
            d="M252.345 17.7678C253.692 19.1892 254.366 21.1637 254.366 23.6899V34.721H248.78V24.4679C248.78 23.2495 248.467 22.3078 247.843 21.6426C247.218 20.9774 246.358 20.6441 245.263 20.6441C244.168 20.6441 243.308 20.9774 242.684 21.6426C242.059 22.3078 241.747 23.2495 241.747 24.4679V34.721H236.161V24.4679C236.161 23.2495 235.848 22.3078 235.224 21.6426C234.6 20.9774 233.74 20.6441 232.644 20.6441C231.549 20.6441 230.688 20.9774 230.065 21.6426C229.44 22.3078 229.128 23.2495 229.128 24.4679V34.721H223.508V15.8392H229.128V18.2074C229.698 17.4185 230.443 16.7926 231.363 16.3297C232.282 15.8676 233.323 15.6362 234.484 15.6362C235.865 15.6362 237.098 15.9404 238.182 16.5495C239.266 17.1587 240.115 18.0269 240.728 19.155C241.364 18.1179 242.229 17.2715 243.324 16.6172C244.42 15.9637 245.613 15.6362 246.907 15.6362C249.185 15.6362 250.998 16.3465 252.345 17.7678Z"
            fill="black"
          />
          <path
            d="M294.112 17.7678C295.46 19.1892 296.133 21.1637 296.133 23.6899V34.721H290.547V24.4679C290.547 23.2495 290.235 22.3078 289.611 21.6426C288.986 20.9774 288.126 20.6441 287.03 20.6441C285.935 20.6441 285.075 20.9774 284.451 21.6426C283.826 22.3078 283.515 23.2495 283.515 24.4679V34.721H277.928V24.4679C277.928 23.2495 277.615 22.3078 276.991 21.6426C276.367 20.9774 275.507 20.6441 274.412 20.6441C273.316 20.6441 272.456 20.9774 271.832 21.6426C271.208 22.3078 270.895 23.2495 270.895 24.4679V34.721H265.276V15.8392H270.895V18.2074C271.465 17.4185 272.21 16.7926 273.13 16.3297C274.05 15.8676 275.091 15.6362 276.251 15.6362C277.632 15.6362 278.865 15.9404 279.949 16.5495C281.033 17.1587 281.882 18.0269 282.496 19.155C283.131 18.1179 283.996 17.2715 285.092 16.6172C286.187 15.9637 287.381 15.6362 288.674 15.6362C290.952 15.6362 292.765 16.3465 294.112 17.7678Z"
            fill="#BBA383"
          />
          <path
            d="M305.696 34.721H300.077V15.8385H305.696V34.721ZM300.487 12.9797C299.863 12.3822 299.55 11.6428 299.55 10.7629C299.55 9.86117 299.863 9.11081 300.487 8.51256C301.111 7.91505 301.917 7.61593 302.903 7.61593C303.867 7.61593 304.661 7.91505 305.285 8.51256C305.909 9.11081 306.221 9.86117 306.221 10.7629C306.221 11.6428 305.909 12.3822 305.285 12.9797C304.661 13.5772 303.867 13.8764 302.903 13.8764C301.917 13.8764 301.111 13.5772 300.487 12.9797Z"
            fill="#BBA383"
          />
          <path
            d="M321.305 23.4521C321.305 22.4601 320.977 21.6705 320.319 21.0839C319.662 20.4973 318.841 20.204 317.854 20.204C316.912 20.204 316.117 20.4857 315.472 21.0497C314.825 21.6137 314.425 22.415 314.273 23.4521H321.305ZM326.957 26.6668H314.24C314.327 27.84 314.694 28.7373 315.341 29.3574C315.987 29.9782 316.781 30.2882 317.723 30.2882C319.125 30.2882 320.1 29.6791 320.648 28.4608H326.629C326.322 29.7016 325.768 30.8181 324.969 31.8108C324.169 32.8035 323.167 33.5815 321.962 34.1455C320.757 34.7096 319.41 34.9912 317.92 34.9912C316.124 34.9912 314.524 34.5968 313.122 33.8071C311.72 33.0182 310.625 31.8901 309.836 30.4236C309.047 28.9571 308.653 27.2424 308.653 25.2796C308.653 23.3168 309.041 21.6028 309.819 20.1356C310.598 18.6698 311.687 17.5417 313.09 16.7521C314.491 15.9624 316.102 15.568 317.92 15.568C319.695 15.568 321.272 15.9515 322.652 16.7186C324.033 17.4857 325.112 18.5796 325.889 20.0009C326.667 21.4216 327.056 23.0802 327.056 24.9747C327.056 25.5161 327.023 26.0809 326.957 26.6668Z"
            fill="#BBA383"
          />
          <path
            d="M330.391 12.9789C329.767 12.3814 329.455 11.6419 329.455 10.762C329.455 9.8603 329.767 9.10995 330.391 8.51171C331.016 7.91492 331.821 7.61507 332.807 7.61507C333.77 7.61507 334.564 7.91492 335.189 8.51171C335.813 9.10995 336.126 9.8603 336.126 10.762C336.126 11.6419 335.813 12.3814 335.189 12.9789C334.564 13.5764 333.77 13.8755 332.807 13.8755C331.821 13.8755 331.016 13.5764 330.391 12.9789ZM335.633 37.1568C335.633 39.4806 335.063 41.1552 333.924 42.1821C332.785 43.209 331.197 43.7221 329.159 43.7221H326.957V38.8154H328.305C328.917 38.8154 329.356 38.6909 329.619 38.4428C329.881 38.1939 330.014 37.7885 330.014 37.2244V15.8384H335.633V37.1568Z"
            fill="#BBA383"
          />
          <path
            d="M342.862 34.1458C341.613 33.5825 340.628 32.8096 339.904 31.8278C339.182 30.8467 338.776 29.747 338.689 28.5287H344.242C344.308 29.183 344.604 29.7128 345.13 30.1189C345.655 30.525 346.301 30.7281 347.069 30.7281C347.769 30.7281 348.312 30.5876 348.695 30.3053C349.078 30.0229 349.27 29.6568 349.27 29.2048C349.27 28.6641 348.996 28.2638 348.448 28.004C347.901 27.7449 347.014 27.4574 345.787 27.1408C344.472 26.8257 343.377 26.4931 342.501 26.143C341.624 25.793 340.869 25.2406 340.233 24.4851C339.597 23.729 339.281 22.7079 339.281 21.4226C339.281 20.3396 339.571 19.3528 340.151 18.4612C340.731 17.5704 341.586 16.8652 342.714 16.3463C343.842 15.8281 345.184 15.5683 346.74 15.5683C349.04 15.5683 350.853 16.1549 352.179 17.328C353.504 18.5005 354.266 20.058 354.462 21.9975H349.27C349.182 21.3433 348.904 20.8251 348.433 20.4408C347.961 20.058 347.342 19.8658 346.575 19.8658C345.918 19.8658 345.414 19.9961 345.064 20.2552C344.713 20.5143 344.539 20.8695 344.539 21.3207C344.539 21.8622 344.818 22.2683 345.377 22.539C345.935 22.8098 346.806 23.0805 347.989 23.3512C349.347 23.7122 350.453 24.0674 351.308 24.4167C352.162 24.7668 352.912 25.3308 353.559 26.1088C354.205 26.8876 354.539 27.9312 354.561 29.239C354.561 30.3446 354.259 31.3322 353.657 32.2004C353.054 33.0687 352.189 33.7513 351.061 34.2477C349.933 34.7433 348.624 34.9922 347.134 34.9922C345.535 34.9922 344.112 34.7098 342.862 34.1458Z"
            fill="#BBA383"
          />
          <path
            d="M358.061 20.1366C358.839 18.6708 359.923 17.5427 361.315 16.7531C362.705 15.9634 364.299 15.5683 366.095 15.5683C368.396 15.5683 370.319 16.1891 371.863 17.4299C373.408 18.6708 374.421 20.419 374.903 22.6744H368.922C368.418 21.2312 367.443 20.5092 365.997 20.5092C364.967 20.5092 364.145 20.9211 363.533 21.7443C362.919 22.5681 362.612 23.7464 362.612 25.2806C362.612 26.8148 362.919 27.9931 363.533 28.8162C364.145 29.6408 364.967 30.052 365.997 30.052C367.443 30.052 368.418 29.33 368.922 27.8861H374.903C374.421 30.0971 373.402 31.8343 371.847 33.0971C370.291 34.3605 368.374 34.9922 366.095 34.9922C364.299 34.9922 362.705 34.5978 361.315 33.8081C359.923 33.0185 358.839 31.8904 358.061 30.4239C357.283 28.9581 356.895 27.2434 356.895 25.2806C356.895 23.3178 357.283 21.6038 358.061 20.1366Z"
            fill="#BBA383"
          />
          <path
            d="M396 15.8389V34.7213H390.38V32.1493C389.811 32.9841 389.038 33.6551 388.064 34.1631C387.089 34.6704 386.01 34.9244 384.827 34.9244C383.424 34.9244 382.187 34.6027 381.114 33.9594C380.04 33.3167 379.207 32.3866 378.616 31.1676C378.024 29.95 377.729 28.5177 377.729 26.8707V15.8389H383.316V26.092C383.316 27.3561 383.633 28.3372 384.268 29.0359C384.904 29.736 385.758 30.0853 386.831 30.0853C387.927 30.0853 388.792 29.736 389.428 29.0359C390.063 28.3372 390.38 27.3561 390.38 26.092V15.8389H396Z"
            fill="#BBA383"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.8332 11.4764C26.8332 17.2839 31.4053 21.9919 37.045 21.9919C42.6852 21.9919 47.2568 17.2839 47.2568 11.4764C47.2568 5.66901 42.6852 0.960938 37.045 0.960938C31.4053 0.960938 26.8332 5.66901 26.8332 11.4764Z"
            fill="#BBA383"
          />
        </svg>
      </StyledLogo>
    </Link>
  )
}

export default Logo
